:root {
  /* Tints generated here: https://maketintsandshades.com/ */
  --purple: #745bb4;
  --dark-purple: #6852a2;
  --darker-purple: #5d4990;
  --light-purple: #826bbc;
  --lighter-purple: #907cc3;
  --lightest-purple: #9e8ccb;

  --green: #21928e;
  --dark-green: #1e8380;
  --darker-green: #1a7572;
  --light-green: #379d99;
  --lighter-green: #4da8a5;

  --coral: #f06256;
  --gold: #c58b2b;
  --off-white: #f1eff0;

  --blue: #689ec0;
  --red: #c92828;
  --error-red: #d86e6e;

  --black: #3a3532;
  --lighter-black: #38383c;

  --white: #fff;

  --grey: #999;
  --dark-grey: #777;
  --darker-grey: #666;
  --darkest-grey: #555;
  --light-grey: #ddd;
  --lighter-grey: #e7e7e7;
  --lightest-grey: #eee;

  --track-red: #f73b35;
  --track-yellow: #fde434;
  --track-blue: #14bae8;
  --track-pink: #f9959f;
  --track-orange: #ff7550;
  --track-ocean-blue: #157893;
  --track-sunflower: #ffce33;
}

:global {
  .hOne,
  .hTwo,
  .hThree,
  .hFour,
  .hFive {
    font-family: Obibok;
    font-weight: bold;
    line-height: 1.25em;
  }

  .hOne {
    font-size: 4.25rem;
  }

  .hTwo {
    font-size: 3rem;
  }

  .hThree {
    font-size: 2.25rem;
  }

  .hFour {
    font-size: 1.75rem;
  }

  .hFive {
    font-size: 1.5rem;
    line-height: 1.5em;
  }

  .hSix {
    font-family: Obibok;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5em;
  }

  .smallFont {
    font-family: Obibok;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
    line-height: 1.25rem;
  }

  .paragraph,
  .hyperLink,
  .orderedList,
  .unorderedList {
    font-family: Obibok;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 0.1rem;
    line-height: 30px;

    @media screen and (max-width: 767px) {
      font-size: 0.9rem;
      line-height: 1.75rem;
    }
  }

  .hyperLink {
    color: var(--purple);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .orderedList,
  .unorderedList {
    list-style: none;
    padding-left: 0;

    li {
      margin-left: 3rem;
      padding-bottom: 1.75rem;
      position: relative;

      &::before {
        margin-left: -3rem;
        position: absolute;
      }
    }
  }

  .orderedList {
    li {
      counter-increment: item;

      &::before {
        content: counter(item) ".";
      }
    }
  }

  .unorderedList {
    li::before {
      content: "\2014\a0";
    }
  }

  .svgIcon g {
    transition: fill 0.2s ease-in-out;
  }

  .backgroundWhite {
    background-color: white;
  }

  .pageContainer {
    min-height: 500px;
    padding-bottom: 3.75rem;
    padding-top: 7.5rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 2.75rem;
    }

    > *:not(.fullWidth) {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
    }

    > *:last-child {
      padding-bottom: 0;
    }
  }
}
