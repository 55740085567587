.flash {
  font-family: Obibok;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
}

.admin {
  padding: 1.25em;
  border-radius: 4px;
  font-size: 16px;

  ul {
    padding-left: 1em;
    margin: 0;
  }

  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.public {
  background: var(--blue);
  margin-bottom: -30px;
  padding: 15px;
  color: var(--white);
}

.error {
  margin: 2em 0 -2em;
  background: var(--error-red);
}

.success {
  margin: 2em 0 -2em;
  background: var(--green);
}
