.groupedInputs {
  display: flex;

  .fieldRow {
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.fieldRow {
  flex: 1 1 0;
  margin-bottom: 1em;
}

.input {
  font-family: NeuzeitGro, georgia;
  font-weight: 400;
  color: var(--black);
  box-sizing: border-box;
  border: 2px solid var(--grey);
  font-size: 16px;
  height: 46px;
  outline: none;
  padding: 0 10px;
  width: 100%;

  &:focus {
    border-color: var(--purple);
  }

  &:disabled {
    background-color: #f2f2f2;
  }
}

.adminInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: NeuzeitGro, georgia;
  font-size: 16px;
  font-weight: 300;
  padding: 0.5em;
  width: 100%;

  &:focus {
    border: 1.5px solid #2684ff;
  }
}

.inputError {
  border-color: var(--error-red);
  margin-top: 0;
}

.label {
  color: #333;
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  line-height: 1.5rem;

  &.admin {
    font-family: NeuzeitGroBlk, georgia;
    font-size: 15px;
    font-weight: 600;

    :global(.label-hint) {
      font-family: NeuzeitGro;
      font-weight: bold;
    }
  }
}

.errorMsg {
  animation: slideInDown 0.2s;
  color: var(--red);
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  margin-top: 3px;
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.columnWrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }

  .column {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1.5em;

      @media screen and (max-width: 1180px) {
        margin-right: 0;
      }
    }
  }
}

.submitBtn {
  display: flex;
  margin-left: auto;
  margin-top: 2em;
}
