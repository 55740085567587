.button {
  border-radius: 4px;
  border: 1px solid var(--grey);
  background: var(--white);
  cursor: pointer;
  font-family: Obibok;
  font-size: 0.75rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  padding: 1em 2em;
  text-transform: uppercase;

  &:hover {
    border: 1px solid var(--darkest-grey);
  }

  &:active {
    border-style: solid;
  }
}

.black {
  composes: button;
  background: var(--black);
  color: var(--white);

  &:hover {
    background: #000;
  }

  &:active {
    border-style: solid;
    background: var(--black);
    color: var(--white);
  }
}
