.form {
  margin-bottom: 2em;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 28px;
}

.remove {
  padding: 3px 5px;
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 4px;
  color: var(--grey);
  border: 1px solid var(--grey);
  background: var(--white);

  &:hover {
    color: var(--darkest-grey);
    border: 1px solid var(--grey);
  }

  &:active {
    border-style: solid;
  }
}

.cornerLabel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
