.label {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    cursor: pointer;
  }
}

.labelText {
  margin-left: 4px;
  cursor: pointer;
}

.disabled {
  opacity: 0.4;
}

.admin {
  .labelText {
    font-size: 16px;
    font-weight: 400;
  }
}
