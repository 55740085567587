@import "./index.scss";
@import "./uppy.css";

.admin {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: NeuzeitGroBold, georgia;
    font-weight: bold;
  }
  font-family: NeuzeitGro, georgia;
  font-weight: 400;

  button {
    margin: 0.75em;
  }
  :focus {
    outline: none;
  }
  margin: 0 auto 4em;
  width: 75%;

  @media screen and (max-width: 960px) {
    width: 90%;
  }

  .headerWithLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  fieldset {
    background: #f4f4f4;
    margin: 1.5em 0;
    padding: 2em;
    border-radius: 4px;
    border-color: #fff;

    legend,
    label {
      text-transform: capitalize;
    }

    legend {
      font-family: NeuzeitGroBold, georgia;
      font-size: 1.5em;
      font-weight: bold;
    }

    label {
      font-size: 14px;
    }
  }

  textarea {
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 16px;
  }

  .field-row {
    margin-bottom: 1em;
    min-height: 65px;
    width: 100%;

    input[type="text"],
    input[type="number"] {
      padding: 0.5em;
      width: 100%;
      border: 1px solid #ccc;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 300;

      &:focus {
        border: 1.5px solid #2684ff;
      }
    }
  }
}

.error-msg {
  color: #ba0000;
  font-size: 14px;
  margin-top: 2px;
}

.artist-collab-wrapper {
  border: 1px solid #ccc;
  padding: 0.25em;
  margin-bottom: 1em;
}

.cyanite-tags {
  margin-bottom: 10px;
  label {
    margin-right: 5px;
    font-size: 1rem;
  }

  span {
    font-size: 1rem;
    color: #666;
  }
}

.checkboxGroup {
  label {
    display: block;
    margin: 6px 0px;

    span {
      margin-left: 2px;
    }
  }
}

.currentAudioFile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0;

  strong {
    margin-right: 0.5em;
  }
}

.spinner {
  position: relative;
  margin-left: 1.5em;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
