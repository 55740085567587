.label {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 600;
  font-size: 15px;
}

.hint {
  font-size: 12px;
  color: var(--grey);
  font-weight: 400;
  font-style: italic;
  margin-left: 1em;
  margin-bottom: 2px;
  text-transform: none;
}
